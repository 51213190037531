import { getRequest, postRequest, putRequest, deleteRequest } from "@/libs/axios";

let base = "/yethan/workOrderType"

export const addBigType = (params)=>{
    return postRequest(`${base}/big`, params)
}
export const addSmallType = (params)=>{
    return postRequest(`${base}/small`, params)
}
export const updateBigType = (id, params)=>{
    return putRequest(`${base}/big/${id}`, params)
}
export const updateSmallType = (id, params)=>{
    return putRequest(`${base}/small/${id}`, params)
}
export const deleteBigType = (id)=>{
    return deleteRequest(`${base}/big/${id}`)
}
export const deleteSmallType = (id)=>{
    return deleteRequest(`${base}/small/${id}`)
}

export const bigType = (id)=>{
    return getRequest(`${base}/big/${id}`)
}
export const smallType = (id)=>{
    return getRequest(`${base}/small/${id}`)
}

export const bigTypeListPage = (params)=>{
    return getRequest(`${base}/big/listPage`, params)
}
export const smallTypeListPage = (params)=>{
    return getRequest(`${base}/small/listPage`, params)
}
