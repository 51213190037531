<template>
  <b-modal v-model="showModal" id="start-workorder" centered :title="title" size="m" title-class="font-18" hide-footer>
    <div class="flexList check-distri mb-3">
      <label style="width: 16%">大类名称：</label>
      <div class="col-sm-10 p-0 flexList">
        <input
            type="text"
            v-model="form.sortName"
            placeholder="大类名称"
            class="form-control w-100 h30"
        />
      </div>
    </div>
    <div class="flexList check-distri mb-3">
      <label style="width: 16%">排序号：</label>
      <div class="col-sm-10 p-0 flexList">
        <input
            type="text"
            v-model="form.orderNum"
            placeholder="排序号"
            class="form-control w-100 h30"
        />
      </div>
    </div>
    <div class="mt-3 text-center">
      <button
          @click="commit"
          type="button"
          class="btn btn-info h30 w-md mr-3"
      >
        确定提交
      </button>
      <button
          type="button"
          @click="hide()"
          class="btn btn-secondary h30 w-md"

      >
        取消
      </button>
    </div>
  </b-modal>
</template>

<script>
import {bigType} from "@/api/admin/workOrder/type";
import {addBigType, updateBigType} from "../../../../../api/admin/workOrder/type";

export default {
  name: "addType",
  props: {
    title: {
      default: "工单大类",
    },
  },
  data() {
    return {
      showModal: false,
      form: {}
    }
  },
  methods: {
    commit(){
      if(this.form.sid){
        updateBigType(this.form.sid, this.form).then(res=>{
          if(res.status){
            this.showModal = false
            this.$emit("success")
          }
        })
      } else {
        addBigType(this.form).then(res=>{
          if (res.status){
            this.showModal = false
            this.$emit("success")
          }
        })
      }
    },
    show(id) {
      if (id){
        bigType(id).then(res=>{
          if(res.status){
            this.form = res.data
            this.showModal = true
          }
        })
      } else {
        this.form = {}
        this.showModal = true
      }
    },
    hide(){
      this.showModal = false
    }
  }
}
</script>

<style scoped>

</style>