<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .custom-switch {
    margin-top: unset;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    bigTypeListPage
  } from "@/api/admin/workOrder/type";
  import YzSearch from "@/components/form/yzSearch.vue";
  import addType from "./type/addType";
  import {
    deleteBigType
  } from "@/api/admin/workOrder/type";

  /**
   * 工单类别
   */
  export default {
    components: {
      Layout,
      PageHeader,
      YzSearch,
      addType
    },
    data() {
      return {
        title: "工单类别 ",
        items: [{
            text: "工单管理",
            href: "/admin"
          },
          {
            text: "工单大类",
            active: true
          }
        ],
        tableList: [{}],
        pageData: {},
        search: []
      };
    },
    methods: {
      getList() {
        bigTypeListPage(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      deleteItem(id, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteBigType(id).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          })
        });
      }
    },
    mounted() {
      this.getList()
      this.search = [{
        title: "大类名称",
        bound: "sortName",
        type: "input",
      }, ]
    }
  };
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">

        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <b-button variant="outline-info" class="flexList mr-2 condition" @click="$refs.addType.show()"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加大类</b-button>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th>大类名称</th>
                    <th style="width:4%">排序号</th>
                    <th style="width:10%">创建时间</th>
                    <th style="width:3%">编辑</th>
                    <th style="width:3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, i) in tableList" :key="i">
                    <td>{{ (pageData.pageNum - 1) * pageData.pageSize + i + 1 }}</td>
                    <td><a :href="`/admin/workOrderSmallType?sid=${v.sid}`" class="blue-font">
                        {{ v.sortName }}
                      </a></td>
                    <td>{{ v.orderNum }}</td>
                    <td>{{ formatDate(v.createTime) }}</td>
                    <td class="tab-icon">
                      <i class="iconfont icon-edit-two text-dark align-middle mr-1"
                        @click="$refs.addType.show(v.sid)"></i>
                    </td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(v.sid,v.sortName)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <addType ref="addType" @success="getList"></addType>
  </Layout>
</template>
